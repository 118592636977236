















import IconDownload from '@/assets/svg/icons/icon-download.svg?inline';
import IconLink from '@/assets/svg/icons/link.svg?inline';
import IconFileDownloadAlt from '@/assets/svg/icons/file-download-alt.svg?inline';

import {ParsedDocumentationTabEntry} from '@/models/documentationTabs';
import {LinkInterceptionMixin} from '@/utils/mixins/LinkInterceptionMixin';
import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';

@Component({
  components: {IconDownload},
})
export default class DocumentationLink extends mixins(LinkInterceptionMixin) {
  @Prop({required: true}) private item!: ParsedDocumentationTabEntry;

  interceptLink(e: MouseEvent) {
    // Since our links come HTML encoded we need to decode it before we use it
    function htmlDecode(input?: string): string {
      if (!input) {
        return "";
      }
      return new DOMParser().parseFromString(input, "text/html").documentElement.textContent || "";
    }

    if (this.item.linkType === "external" || this.item.linkType ===  "page") {
      window.open(htmlDecode(this.item.link), this.item.linkTarget as string, this.item.linkRel as string);
    } else {
      this.interceptedEvent = e;
    }
  }

  get icon() {
    // switch (this.item.docType) {
    //   case 'brochures':
    //   case 'caseStudies':
    //   case 'corporateDocuments':
    //   case 'sustainabilityPositionPaper':
    //   case 'termsAndCondition':
    //   case 'thinkTrespaMagazine':
    //     default:
    //
    // }
    switch (this.item.linkType) {
      case 'external':
        return IconLink;
      case 'asset':
        return IconFileDownloadAlt;
      case 'page':
        return IconLink;
      default:
        return IconFileDownloadAlt;
    }
  }
}
